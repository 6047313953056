.uxf-typo-h1 {
    @apply text-desktopH1 font-medium;
}

.uxf-typo-h2 {
    @apply text-desktopH2 font-medium;
}

.uxf-typo-h3 {
    @apply text-desktopH3 font-medium;
}

.uxf-typo-h4 {
    @apply text-desktopH4 font-medium;
}

.uxf-typo-h5 {
    @apply text-desktopH5 font-medium;
}

.uxf-typo-h6 {
    @apply text-desktopH6 font-medium;
}

.uxf-typo-body {
    @apply text-body;
}

.uxf-typo-body2 {
    @apply text-body2;
}

.uxf-typo-button {
    @apply text-body font-medium;
}

.uxf-typo-caption {
    @apply text-caption;
}

.uxf-typo-medium {
    @apply text-medium font-medium;
}

.uxf-typo-medium2 {
    @apply text-medium2 font-medium;
}

.uxf-typo-overline {
    @apply text-overline font-medium;
}
