.uxf-input {
    border-radius: theme("borderRadius.2xl");

    &__left-addon,
    &__right-addon {
        :root .light & {
            background-color: theme("colors.invertedHigh");
        }

        &--text {
            color: theme("colors.lightLow");
            font-size: theme("fontSize.body2");
        }
    }

    &__left-addon {
        border-bottom-left-radius: theme("borderRadius.2xl");
        border-top-right-radius: theme("borderRadius.2xl");
    }

    &__right-addon {
        border-bottom-right-radius: theme("borderRadius.2xl");
        border-top-right-radius: theme("borderRadius.2xl");
    }

    &__wrapper {
        border-radius: theme("borderRadius.2xl");

        :root .light & {
            border-color: theme("colors.gray.200");
        }
    }
}
